import styled from "@emotion/styled";
import { Link } from "gatsby";

export interface PlainLinkProps {
  active?: string;
  variant: "dark" | "light";
}

export default styled(Link)<PlainLinkProps>`
  display: inline-block;
  position: relative;
  font-family: ${({ theme }) => theme.font.josefinSans};
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  padding: 8px 0;
  color: ${({ theme, variant }) => (variant === "dark" ? theme.color.white : theme.color.grey700)};
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.2em;
  cursor: pointer;
  user-select: none;
  transition: color 0.2s;

  &:hover {
    color: ${({ theme, variant }) => (variant === "dark" ? theme.color.white : theme.color.grey700)};
  }

  &:active {
    color: ${({ theme, variant }) => (variant === "dark" ? theme.color.white : theme.color.grey700)};
  }

  &:before {
    position: absolute;
    left: 0;
    content: "";
    width: 100%;
    height: 2px;
    background: ${({ theme, variant }) => (variant === "dark" ? theme.color.white : theme.color.grey700)};
    bottom: 0;
    transform: scale(0, 1);
    transition: color 0.2s, transform 0.2s;
    transform-origin: center top;
  }
  &:hover {
    &:before {
      transform: scale(1, 1);
    }
  }
  &:active {
    &:before {
      background: ${({ theme, variant }) => (variant === "dark" ? theme.color.white : theme.color.grey700)};
    }
  }

  ${({ active }) =>
    active === "true" &&
    `
      &:before {
        transform: scale(1, 1);
      }
  `}
`;
