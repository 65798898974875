import styled from "@emotion/styled";

import buttonVariants, { ButtonVariant } from "./variants";

export type ButtonState = "active" | "default" | "hover";

export interface Props {
  block?: boolean;
  className?: string;
  forcedState?: ButtonState;
  inline?: boolean;
  type?: "button" | "submit";
  variant?: ButtonVariant;
}

export default styled.button<Props>`
  display: ${({ block, inline }) =>
    block ? "block" : inline ? "inline-flex" : "flex"};
  justify-content: center;
  align-items: center;
  padding: 11px 20px 9px;
  height: 60px;
  font-family: ${({ theme }) => theme.font.josefinSans};
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  border: none;
  border-radius: 0;
  border: 1px solid transparent;
  letter-spacing: 0.2em;
  box-sizing: border-box;
  outline: none;
  transition: background-color 0.2s linear, border 0.2s linear;
  user-select: none;
  cursor: pointer;
  text-transform: uppercase;

  ${({ theme, forcedState, variant = "light" }) =>
    buttonVariants[variant]?.(theme, forcedState)}
`;
