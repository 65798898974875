/** @jsx jsx */
import { useLocation } from "@reach/router";
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { WpMenuItem, Maybe } from "../../../gatsby-types";

import PlainLink, { PlainLinkProps } from "../PlainLink";

export interface MenuItem {
  node: Pick<WpMenuItem, "path" | "url"> & {
    key: string;
    title: Maybe<string> | undefined;
  };
}

interface MenuProps {
  className?: string;
  items: MenuItem[];
  variant: PlainLinkProps["variant"];
}

const Menu: React.FC<MenuProps> = ({ className, items, variant }) => {
  const location = useLocation();
  return (
    <ul className={className} css={{ gridTemplateColumns: `repeat(${items.length}, 1fr)` }}>
      {items.map((item, idx) => {
        if (!item.node.path) {
          return undefined;
        }

        const isActive = item.node.path.toString() === location.pathname;
        return (
          <li key={idx}>
            <PlainLink to={item.node.path.toString()} variant={variant} active={isActive.toString()}>
              {item.node.title}
            </PlainLink>
          </li>
        );
      })}
    </ul>
  );
};

export default styled(Menu)`
  display: flex;
  padding: 0;
  list-style: none;

  li {
    &:not(:first-of-type) {
      margin-left: 40px;
    }
  }

  ${PlainLink} {
    font-size: 12px;
    padding: 6px 0;
  }
`;
