/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import MenuItems, { MenuItem } from "./MenuItems";

import { Breakpoint, media, themeColor } from "../../theme";

const LogoAnchor = styled.a``;

const OverlayMenuOpen = styled.img`
  display: block;
  width: 30px;
  height: 23px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;

  ${media(Breakpoint.Tablet)} {
    display: none;
  }
`;

const Logo = styled.img`
  display: block;
  width: 120px;
  height: 60px;
  margin: 20px auto 0;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s linear, visibility 0.2s linear;

  ${media(Breakpoint.Tablet)} {
    margin: 0 auto;
  }

  ${media(Breakpoint.TabletPlus)} {
    width: 200px;
    height: 100px;
  }
`;

const LogoSticky = styled.img`
  position: absolute;
  top: 20px;
  left: 50%;
  display: block;
  width: 17px;
  height: 24px;
  margin: 0 0 0 -8px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s linear, visibility 0.2s linear;
`;

const MobileMenu = styled.div`
  display: block;

  ${media(Breakpoint.Tablet)} {
    display: none;
  }
`;

const TabletUpMenu = styled.div`
  display: none;

  ${media(Breakpoint.Tablet)} {
    position: relative;
    display: grid;
    justify-content: center;
    grid-gap: 40px;
  }

  ${media(Breakpoint.TabletPlus)} {
    display: none;
  }
`;

const TabletPlusUpMenu = styled.div`
  display: none;

  ${media(Breakpoint.TabletPlus)} {
    display: grid;
    grid-template-columns: 1fr 200px 1fr;
    grid-gap: 50px;
  }

  @media (min-width: 1090px) {
    grid-gap: 140px;
  }

  > div {
    &:first-of-type {
      ${MenuItems} {
        justify-content: flex-end;
      }
    }
  }
`;

interface MenuProps {
  className?: string;
  overlayMenuToggle: (action: "open" | "close") => void;
  sticky?: boolean;
  dark?: boolean;
  items: MenuItem[];
}

const Menu: React.FC<MenuProps> = ({ className, dark, items, overlayMenuToggle }) => {
  const menuItemsLeft = [...items].splice(0, 3);
  const menuItemsRight = [...items].splice(-3);

  const LogoElement: JSX.Element = (
    <LogoAnchor href="/">
      <Logo alt="logo" src={`/logo${dark ? "-dark" : ""}.svg`} width={200} height={100} />
      <LogoSticky alt="logo" src={`/logo-sticky${dark ? "-dark" : ""}.svg`} width={17} height={24} />
    </LogoAnchor>
  );
  return (
    <div className={className}>
      <MobileMenu>
        <OverlayMenuOpen
          alt="menu"
          src={`/menu-open${dark ? "-dark" : ""}.svg`}
          onClick={() => overlayMenuToggle("open")}
        />
        {LogoElement}
      </MobileMenu>
      <TabletUpMenu>
        <div>{LogoElement}</div>
        <div>
          <MenuItems items={items} variant={dark ? "light" : "dark"} />
        </div>
      </TabletUpMenu>
      <TabletPlusUpMenu>
        <div>
          <MenuItems items={menuItemsLeft} variant={dark ? "light" : "dark"} />
        </div>
        <div>{LogoElement}</div>
        <div>
          <MenuItems items={menuItemsRight} variant={dark ? "light" : "dark"} />
        </div>
      </TabletPlusUpMenu>
    </div>
  );
};

export default styled(Menu)`
  position: fixed;
  width: 100%;
  height: 80px;
  top: 0px;
  left: 0;
  z-index: 99;
  padding: 20px;

  ${media(Breakpoint.Tablet)} {
    padding-top: 0;
    position: absolute;
    height: 120px;
    top: 40px;
  }

  ${media(Breakpoint.DesktopLarge)} {
    top: 80px;
  }

  ${MenuItems} {
    ${media(Breakpoint.TabletPlus)} {
      margin-top: 61px;
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 63px;
    background: ${themeColor("grey700")};
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s linear;
  }

  ${({ sticky }) =>
    sticky &&
    `
    ${media(Breakpoint.TabletDown)} {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;

      &:before {
        opacity: 1;
        visibility: visible;
      }

      ${Logo} {
        opacity: 0;
        visibility: hidden;
      }

      ${LogoSticky} {
        opacity: 1;
        visibility: visible;
      }
    }
  `}
`;
