/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { DetailedHTMLProps, InputHTMLAttributes } from "react";

import FieldWrapper from "./FieldWrapper";
import InputElement from "./InputElement";
import InputElementWrapper from "./InputElementWrapper";

import useIsFocused from "../../hooks/useIsFocused";

const Error = styled.p`
  color: ${({ theme }) => theme.color.red};
  font-weight: 700;
  font-size: 12px;
  margin: 5px 0 0;
`;

type InputElementProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export interface InputProps {
  autoComplete?: InputElementProps["autoComplete"];
  ariaLabel?: string;
  className?: string;
  disabled?: boolean;
  error?: string;
  id: InputElementProps["id"];
  initialFocus?: boolean;
  label?: string;
  onChange?: InputElementProps["onChange"];
  onBlur?: (event: React.FocusEvent) => void;
  onKeyDownEvent?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPressEvent?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  pattern?: string;
  placeholder?: string;
  readOnly?: InputElementProps["readOnly"];
  required?: boolean;
  type?: InputElementProps["type"];
  value?: string;
}

function Input({
  autoComplete,
  ariaLabel,
  className,
  disabled,
  error,
  id,
  initialFocus,
  onChange,
  onBlur: customOnBlur,
  onKeyDownEvent,
  onKeyPressEvent,
  onFocus: customOnFocus,
  pattern,
  placeholder,
  readOnly,
  type = "text",
  value,
}: InputProps) {
  const hasError = !!error;

  /* if you're ever doing something that requires a ref to this element outside this component
   * you'll have to look into something like https://github.com/gregberge/react-merge-refs (or another
   * util that merges refs) and work that into this hook */
  const { isFocused, onBlur, onFocus, ref } = useIsFocused<HTMLInputElement>({
    initialFocus,
    onBlur: customOnBlur,
    onFocus: customOnFocus,
  });

  const keyPressListener = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyPressEvent) {
      onKeyPressEvent(e);
    } else if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <FieldWrapper className={className}>
      <InputElementWrapper hasError={hasError} hasFocus={isFocused} readOnly={readOnly}>
        <InputElement
          autoComplete={autoComplete}
          aria-label={ariaLabel || id}
          ref={ref}
          id={id}
          disabled={disabled}
          onBlur={onBlur}
          onChange={disabled ? undefined : onChange}
          onKeyDown={onKeyDownEvent}
          onKeyPress={keyPressListener}
          onFocus={onFocus}
          pattern={pattern}
          placeholder={placeholder}
          readOnly={readOnly}
          type={type}
          value={value}
        />
      </InputElementWrapper>
      {error && <Error>{error}</Error>}
    </FieldWrapper>
  );
}

export default styled(Input)`
  &:hover {
    ${InputElement} {
      &::placeholder {
        color: ${({ theme }) => theme.color.black};
      }
    }
  }
`;
