import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import Button from "../Button";
import ButtonAnchor from "../Button/ButtonAnchor";

import { media, Breakpoint, themeColor, alpha } from "../../theme";

const BackgroundContainer = styled.div`
  position: relative;
  display: grid;
  grid-area: 1/1;
  align-items: center;

  > div {
    height: 100%;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => alpha(theme.color.grey700, 0.8)};
  }
`;

const HeroContainer = styled.div`
  display: flex;
  grid-area: 1/1;
  flex-direction: column;
  position: relative;
  height: 100%;
  place-items: center;
  align-self: center;
  padding: 0 20px;
`;

const HeroContainerElements = styled.div<{ isHomepage: boolean }>`
  display: grid;
  grid-gap: 20px;
  justify-items: center;
  max-width: ${({ isHomepage }) => (isHomepage ? 900 : 980)}px;
  padding: 150px 0 50px;

  ${media(Breakpoint.Mobile)} {
    padding: 150px 20px 50px;
  }

  ${media(Breakpoint.Tablet)} {
    padding: ${({ isHomepage }) => (isHomepage ? 270 : 240)}px 40px 50px;
  }

  ${ButtonAnchor} {
    display: block;
    width: 275px;

    ${Button} {
      width: 100%;
    }
  }
`;

const Header = styled.h1`
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.1em;
  color: ${themeColor("white")};
  text-transform: uppercase;
  text-align: center;

  ${media(Breakpoint.Tablet)} {
    font-size: 46px;
    line-height: 56px;
  }
`;

const SubHeader = styled.h2`
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.3em;
  color: ${themeColor("white")};
  text-transform: uppercase;
  text-align: center;
`;

const Paragraph = styled.p`
  font-size: 14px;
  line-height: 26px;
  color: ${themeColor("white")};
  text-align: center;

  ${media(Breakpoint.Tablet)} {
    font-size: 18px;
    line-height: 34px;
  }
`;

interface FooterCTAProps {
  className?: string;
  header: string;
  subHeader?: string;
  paragraph?: string;
  buttonHref?: string;
  buttonLabel?: string;
  page: "about" | "cabins" | "contact" | "generic" | "homepage" | "location" | "retreats";
}

const FooterCTA: React.FC<FooterCTAProps> = ({
  className,
  header,
  page,
  subHeader,
  paragraph,
  buttonHref,
  buttonLabel,
}) => {
  let imgElement: JSX.Element | null;

  if (page === "homepage") {
    imgElement = (
      <StaticImage
        src={`../../assets/images/hero_homepage.jpg`}
        alt="CTA"
        layout="fullWidth"
        objectFit="cover"
        objectPosition="top center"
        css={{ maxHeight: "700px" }}
      />
    );
  } else if (page === "about") {
    imgElement = (
      <StaticImage
        src={`../../assets/images/hero_about.jpg`}
        alt="CTA"
        layout="fullWidth"
        objectFit="cover"
        objectPosition="top center"
        css={{ maxHeight: "500px" }}
      />
    );
  } else if (page === "cabins") {
    imgElement = (
      <StaticImage
        src={`../../assets/images/hero_cabins.jpg`}
        alt="CTA"
        layout="fullWidth"
        objectFit="cover"
        objectPosition="top center"
        css={{ maxHeight: "500px" }}
      />
    );
  } else if (page === "retreats") {
    imgElement = (
      <StaticImage
        src={`../../assets/images/hero_retreats.jpg`}
        alt="CTA"
        layout="fullWidth"
        objectFit="cover"
        objectPosition="top center"
        css={{ maxHeight: "500px" }}
      />
    );
  } else if (page === "location") {
    imgElement = (
      <StaticImage
        src={`../../assets/images/hero_location.jpg`}
        alt="CTA"
        layout="fullWidth"
        objectFit="cover"
        objectPosition="top center"
        css={{ maxHeight: "500px" }}
      />
    );
  } else {
    imgElement = null;
  }

  return (
    <div className={className}>
      <BackgroundContainer>{imgElement}</BackgroundContainer>
      <HeroContainer>
        <HeroContainerElements isHomepage={page === "homepage"}>
          <Header>{header}</Header>
          {subHeader && <SubHeader>{subHeader}</SubHeader>}
          {paragraph && <Paragraph>{paragraph}</Paragraph>}
          {buttonHref && buttonLabel && (
            <ButtonAnchor css={{ marginTop: "20px" }} href={buttonHref}>
              <Button variant="transparent">{buttonLabel}</Button>
            </ButtonAnchor>
          )}
        </HeroContainerElements>
      </HeroContainer>
    </div>
  );
};

export default styled(FooterCTA)`
  position: relative;
  display: grid;
  max-height: ${({ page }) => (page === "homepage" ? "700px" : "500px")};
  background: ${themeColor("grey100")};

  ${media(Breakpoint.DesktopLarge)} {
    height: ${({ page }) => (page === "homepage" ? "740px" : "540px")};
    max-height: none;
    padding: 40px 40px 0;
  }

  ${({ page, theme }) =>
    (page === "contact" || page === "generic") &&
    `
      ${Header} {
        color: ${theme.color.grey700};
      }

      ${BackgroundContainer} {
        &:after {
          display: none;
        }
      }
  `}
`;
