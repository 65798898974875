import { Theme } from "@emotion/react";

export const theme: Theme = {
  color: {
    black: "#000000",
    red: "#FF2E00",
    white: "#FFFFFF",
    grey100: "#F3F6F9",
    grey300: "#E5E7EA",
    grey400: "#C2C3C3",
    grey600: "#626363",
    grey700: "#0D0F0F",
    transparent: "transparent",
  },

  font: {
    // 400, 700
    josefinSans: "'Josefin Sans', sans-serif",
    // 400, 700
    openSans: "'Open Sans', sans-serif",
  },
};

export enum Breakpoint {
  LegacyMobile = "max-width: 320px",
  Mobile = "min-width: 480px",
  MobileDown = "max-width: 480px",
  MobilePlus = "min-width: 670px",
  MobilePlusDown = "max-width: 670px",
  TabletDown = "max-width: 768px",
  Tablet = "min-width: 768px",
  TabletPlus = "min-width: 1000px",
  Desktop = "min-width: 1200px",
  DesktopLarge = "min-width: 1352px",
  DesktopExtraLarge = "min-width: 1920px",
  DesktopUltraWide = "min-width: 2560px",
}

export function media(b: Breakpoint): string {
  return `@media (${b})`;
}

export function themeColor(color: keyof Theme["color"]) {
  return ({ theme }: { theme: Theme }) => {
    return theme.color[color];
  };
}

export function alpha(color: string, a: number): string {
  if (a < 0 || a > 1) {
    throw new Error("invalid alpha value");
  }

  if (color.length !== 7) {
    throw new Error("invalid color length");
  }

  return color + Math.floor(a * 255).toString(16);
}
