import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";

import SignUpForm from "../SignUpForm";

import { media, Breakpoint, themeColor } from "../../theme";

const MAX_HEIGHT_MOBILE = "590px";
const MAX_HEIGHT_TABLETUP = "704px";

const BackgroundContainer = styled.div`
  position: relative;
  display: grid;
  grid-area: 1/1;
  align-items: center;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(0deg, rgba(243, 246, 249, 0) 0%, rgba(243, 246, 249, 1) 100%);

    ${media(Breakpoint.MobilePlus)} {
      height: 200px;
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(13, 15, 15, 0) 0%, rgba(13, 15, 15, 0.8) 100%);
    z-index: 2;
  }

  > div {
    &:first-of-type {
      ${media(Breakpoint.Tablet)} {
        display: none;
      }
    }

    &:last-of-type {
      display: none;

      ${media(Breakpoint.Tablet)} {
        display: block;
      }
    }
  }
`;

const CTAContainer = styled.div`
  display: flex;
  grid-area: 1/1;
  flex-direction: column;
  position: relative;
  place-items: center;
  align-self: center;
  padding: 0 20px;
  z-index: 3;
`;

const Logo = styled.img`
  display: block;
  margin: 0 auto 40px;
`;

const Intro = styled.p`
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: ${themeColor("white")};
  margin: 0 0 40px;
`;

const Outro = styled.p`
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: ${themeColor("white")};
  margin: 0 0 40px;
`;

const ThankYou = styled.p`
  text-align: center;
  font-family: ${({ theme }) => theme.font.josefinSans};
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${themeColor("white")};
  max-width: 440px;
  margin: 10px auto 0;

  ${media(Breakpoint.MobilePlus)} {
    font-size: 36px;
    line-height: 44px;
  }
`;

interface FooterCTAProps {
  className?: string;
}

const FooterCTA: React.FC<FooterCTAProps> = ({ className }) => {
  const [isSignUpFormSubmitted, setIsSignUpFormSubmitted] = useState(false);
  let formContent: JSX.Element;

  if (isSignUpFormSubmitted) {
    formContent = <ThankYou>Thank you for Subscribing</ThankYou>;
  } else {
    formContent = (
      <React.Fragment>
        <Intro>Subscribe to receive updates on availabilty and promotional offers.</Intro>
        <SignUpForm css={{ margin: "0 0 20px" }} onSuccess={() => setIsSignUpFormSubmitted(true)} />
        <Outro>We respect your privacy</Outro>
      </React.Fragment>
    );
  }

  return (
    <div className={className}>
      <BackgroundContainer>
        <StaticImage
          src="../../assets/images/cta_form_mobile.jpg"
          alt="CTA"
          layout="fullWidth"
          objectPosition="top center"
          css={{ maxHeight: MAX_HEIGHT_MOBILE }}
        />
        <StaticImage
          src="../../assets/images/cta_form_tabletUp.jpg"
          alt="CTA"
          layout="fullWidth"
          objectPosition="top center"
          css={{ maxHeight: MAX_HEIGHT_TABLETUP }}
        />
      </BackgroundContainer>
      <CTAContainer>
        <Logo alt="logo" src={`/logo-footer.svg`} width={200} height={90} />
        {formContent}
      </CTAContainer>
    </div>
  );
};

export default styled(FooterCTA)`
  position: relative;
  display: grid;
  max-height: ${MAX_HEIGHT_MOBILE};

  ${media(Breakpoint.Tablet)} {
    max-height: ${MAX_HEIGHT_TABLETUP};
  }
`;
