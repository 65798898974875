/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { PropsWithChildren, RefObject } from "react";

interface FieldWrapperProps {
  className?: string;
  onClick?: React.MouseEventHandler;
  wrapperRef?: RefObject<HTMLDivElement>;
}

export function FieldWrapper({
  children,
  className,
  onClick,
  wrapperRef,
}: PropsWithChildren<FieldWrapperProps>) {
  return (
    <div
      ref={wrapperRef}
      className={className}
      onClick={onClick}
      role="presentation"
    >
      {children}
    </div>
  );
}

export default styled(FieldWrapper)`
  position: relative;
`;
