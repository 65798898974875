import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

interface InputElementWrapperProps {
  hasError?: boolean;
  hasFocus?: boolean;
  readOnly?: boolean;
}

function handleStates({
  hasError,
  hasFocus,
  readOnly,
  theme,
}: InputElementWrapperProps & { theme: Theme }) {
  if (readOnly) {
    return;
  } else if (hasError) {
    return css`
      && {
        label: hasError;
        border-color: ${theme.color.red};
        box-shadow: inset 0 0 0 1px ${theme.color.red};

        &:hover {
          border-color: ${theme.color.red};
          box-shadow: inset 0 0 0 1px ${theme.color.red};
        }
      }
    `;
  } else if (hasFocus) {
    return css`
      && {
        label: hasFocus;
      }
    `;
  }
  return;
}

const InputElementWrapper = styled.div<InputElementWrapperProps>`
  position: relative;
  display: flex;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 0;
  border: 1px solid ${({ theme }) => theme.color.grey700};
  box-sizing: border-box;
  height: 50px;
  padding: 0;
  align-items: center;
  transition: color 0.2s linear, box-shadow 0.2s linear,
    background-color 0.2s linear, border-color 0.2s linear;

  ${handleStates}
`;

export default InputElementWrapper;
