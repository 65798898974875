import styled from "@emotion/styled";

export default styled.input`
  font-family: ${({ theme }) => theme.font.josefinSans};
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 2.8px;
  width: 100%;
  height: 100%;
  padding: 3px 30px 0;
  border: none;
  outline: none;
  background: none;
  -webkit-appearance: none;

  &::placeholder {
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.grey600};
    transition: color 0.2s linear;
  }

  ${({ type }) =>
    type === "search" &&
    `
        &::-ms-clear,
        &::-ms-reveal {
            display: none;
            width: 0;
            height: 0;
        }

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }
    `}
`;
