import styled from "@emotion/styled";
import React from "react";

import FooterCTA from "../FooterCTA";

import { media, Breakpoint, themeColor } from "../../theme";

const FooterInner = styled.div`
  padding: 40px 20px;
  color: ${themeColor("white")};
  background-color: ${themeColor("grey700")};

  ${media(Breakpoint.MobilePlus)} {
    display: grid;
    grid-template-columns: repeat(2, 210px);
    grid-gap: 40px 20px;
    justify-content: center;
  }

  p {
    font-size: 12px;
    line-height: 24px;
    text-align: center;

    a {
      color: ${themeColor("white")};
      text-decoration: none;
    }

    &:last-of-type {
      color: ${themeColor("grey600")};

      ${media(Breakpoint.MobilePlus)} {
        grid-column: 1 / span 2;
      }
    }
  }
`;

interface FooterProps {
  className?: string;
  includeCTA?: boolean;
}

const Footer: React.FC<FooterProps> = ({ className, includeCTA }) => {
  const currentDate = new Date();
  return (
    <footer className={className}>
      {includeCTA && <FooterCTA />}
      <FooterInner>
        <p css={{ margin: "0 0 10px;" }}>
          2450 Tofino Ucluelet Hwy
          <br />
          Ucluelet, BC, V0R 3A0
        </p>
        <p css={{ margin: "0 0 20px;" }}>
          <a href="mailto:info@florenciabay.ca">info@florenciabay.ca</a>
          <br />
          <a href="tel:+12507252779">250-725-2779</a>
        </p>
        <p css={{ margin: 0 }}>© {currentDate.getFullYear()} Florencia Bay Retreat</p>
      </FooterInner>
    </footer>
  );
};

export default styled(Footer)`
  position: relative;
`;
