import { css, Theme } from "@emotion/react";

import { ButtonState } from ".";

type ButtonVariation = {
  [key in ButtonState]: {
    backgroundColor: string;
    borderColor: string;
    textColor: string;
  };
};

function getButtonsColors(
  variant: keyof typeof buttonVariants = "light"
): ButtonVariation {
  if (variant === "light") {
    return {
      default: {
        backgroundColor: "white",
        borderColor: "grey700",
        textColor: "grey700",
      },
      hover: {
        backgroundColor: "grey700",
        borderColor: "grey700",
        textColor: "white",
      },
      active: {
        backgroundColor: "black",
        borderColor: "black",
        textColor: "white",
      },
    };
  } else if (variant === "dark") {
    return {
      default: {
        backgroundColor: "grey700",
        borderColor: "white",
        textColor: "white",
      },
      hover: {
        backgroundColor: "white",
        borderColor: "white",
        textColor: "grey700",
      },
      active: {
        backgroundColor: "white",
        borderColor: "white",
        textColor: "black",
      },
    };
  } else if (variant === "transparent") {
    return {
      default: {
        backgroundColor: "transparent",
        borderColor: "white",
        textColor: "white",
      },
      hover: {
        backgroundColor: "grey700",
        borderColor: "white",
        textColor: "white",
      },
      active: {
        backgroundColor: "black",
        borderColor: "white",
        textColor: "white",
      },
    };
  } else if (variant === "transparentDark") {
    return {
      default: {
        backgroundColor: "transparent",
        borderColor: "grey700",
        textColor: "grey700",
      },
      hover: {
        backgroundColor: "grey700",
        borderColor: "white",
        textColor: "white",
      },
      active: {
        backgroundColor: "black",
        borderColor: "white",
        textColor: "white",
      },
    };
  }

  // light
  return {
    default: {
      backgroundColor: "grey700",
      borderColor: "grey700",
      textColor: "white",
    },
    hover: {
      backgroundColor: "grey700",
      borderColor: "white",
      textColor: "white",
    },
    active: {
      backgroundColor: "black",
      borderColor: "white",
      textColor: "white",
    },
  };
}

function buttonState(
  theme: Theme,
  variation: ButtonVariation,
  state: ButtonState,
  key: keyof ButtonVariation[ButtonState]
): string {
  const color = theme.color[variation[state][key] as keyof Theme["color"]];

  if (!color) {
    return theme.color["black"];
  }

  return color;
}

const generateButtonStates = (
  theme: Theme,
  state: ButtonState,
  variation: ButtonVariation,
  includePseudoSelector?: boolean
) => css`
  ${state === "default" && `font-family: ${theme.font.josefinSans};`}
  ${state !== "default" && includePseudoSelector && `&:${state} {`}
    color: ${buttonState(theme, variation, state, "textColor")};
  background-color: ${buttonState(theme, variation, state, "backgroundColor")};
  border-color: ${buttonState(theme, variation, state, "borderColor")};
  ${state !== "default" && includePseudoSelector && `}`}
`;

const lightTheme = (theme: Theme, forcedState?: ButtonState) => css`
  color: ${theme.color.white};
  ${forcedState
    ? generateButtonStates(theme, forcedState, getButtonsColors("light"))
    : generateButtonStates(theme, "default", getButtonsColors("light"))}

  @media (hover: hover) and (pointer: fine) {
    ${!forcedState &&
    generateButtonStates(theme, "hover", getButtonsColors("light"), true)}
  }

  ${!forcedState &&
  generateButtonStates(theme, "active", getButtonsColors("light"), true)}
`;

const darkTheme = (theme: Theme, forcedState?: ButtonState) => css`
  color: ${theme.color.white};
  ${forcedState
    ? generateButtonStates(theme, forcedState, getButtonsColors("dark"))
    : generateButtonStates(theme, "default", getButtonsColors("dark"))}

  @media (hover: hover) and (pointer: fine) {
    ${!forcedState &&
    generateButtonStates(theme, "hover", getButtonsColors("dark"), true)}
  }

  ${!forcedState &&
  generateButtonStates(theme, "active", getButtonsColors("dark"), true)}
`;

const transparentTheme = (theme: Theme, forcedState?: ButtonState) => css`
  color: ${theme.color.white};
  ${forcedState
    ? generateButtonStates(theme, forcedState, getButtonsColors("transparent"))
    : generateButtonStates(theme, "default", getButtonsColors("transparent"))}

  @media (hover: hover) and (pointer: fine) {
    ${!forcedState &&
    generateButtonStates(theme, "hover", getButtonsColors("transparent"), true)}
  }

  ${!forcedState &&
  generateButtonStates(theme, "active", getButtonsColors("transparent"), true)}
`;

const transparentDarkTheme = (theme: Theme, forcedState?: ButtonState) => css`
  color: ${theme.color.grey700};
  ${forcedState
    ? generateButtonStates(theme, forcedState, getButtonsColors("transparentDark"))
    : generateButtonStates(theme, "default", getButtonsColors("transparentDark"))}

  @media (hover: hover) and (pointer: fine) {
    ${!forcedState &&
    generateButtonStates(theme, "hover", getButtonsColors("transparentDark"), true)}
  }

  ${!forcedState &&
  generateButtonStates(theme, "active", getButtonsColors("transparentDark"), true)}
`;

const buttonVariants = {
  light: lightTheme,
  dark: darkTheme,
  transparent: transparentTheme,
  transparentDark: transparentDarkTheme
} as const;

export type ButtonVariant = keyof typeof buttonVariants;

export default buttonVariants;
