import { Global, ThemeProvider } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { throttle } from "throttle-debounce";
import { useStaticQuery, graphql } from "gatsby";
import { SEOContext } from "gatsby-plugin-wpgraphql-seo";

import Footer from "../Footer";
import Main from "../Main";
import Menu from "../Menu";
import MenuOverlay from "../Menu/MenuOverlay";

import { resetCss } from "../../utils/resetCss";
import { theme } from "../../theme";

const fontCss = `
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&display=swap');
`;

const globalCss = `
  html {
    height: 100%;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
  }

  body {
    height: 100%;
    padding: 0;
    margin: 0;
    color: #000;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
  }

  * {
    box-sizing: inherit;
  }

  .ril__toolbarItemChild {
    font-family: 'Josefin Sans', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 700;
  }
`;

interface LayoutProps {
  darkHeader?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, darkHeader }) => {
  const {
    allWpMenuItem,
    wp: { seo },
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      wp {
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
      allWpMenuItem(filter: { locations: { eq: PRIMARY_MENU } }) {
        edges {
          node {
            key: id
            path
            title: label
            url
          }
        }
      }
    }
  `);
  const [isOverlayMenuOpen, setIsOverlayMenuOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = throttle(100, () => {
      if (ref.current) {
        setSticky(ref.current.getBoundingClientRect().top < -20);
      }
    });

    window.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  const toggleMainMenu = (action: "open" | "close") => {
    setIsOverlayMenuOpen(action === "open");
  };

  return (
    <SEOContext.Provider value={{ global: seo }}>
      <ThemeProvider theme={theme}>
        <CSSTransition
          in={isOverlayMenuOpen}
          classNames="fade"
          timeout={{
            appear: 0,
            enter: 0,
            exit: 200,
          }}
          unmountOnExit
        >
          <MenuOverlay items={allWpMenuItem.edges} overlayMenuToggle={toggleMainMenu} />
        </CSSTransition>
        <Global styles={[fontCss, resetCss, globalCss]} />
        <section css={{ display: "flex", flexDirection: "column", minHeight: "100vh" }} ref={ref}>
          <Menu items={allWpMenuItem.edges} overlayMenuToggle={toggleMainMenu} sticky={isSticky} dark={darkHeader} />
          <Main>{children}</Main>
          <Footer includeCTA />
        </section>
      </ThemeProvider>
    </SEOContext.Provider>
  );
};

export default Layout;
