/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useState } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";

import Button from "../Button";
import InputElementWrapper from "../Input/InputElementWrapper";
import InputField from "../Input/InputField";

import { isValidEmail } from "../../utils";
import { media, Breakpoint } from "../../theme";

export const SubscribeFormInner = styled.form<{ hasError: boolean }>`
  max-width: 440px;
  height: 120px;

  ${media(Breakpoint.Tablet)} {
    height: 50px;
  }

  ${InputElementWrapper} {
    border-color: ${({ hasError, theme }) => (hasError ? theme.color.red : theme.color.white)};

    ${media(Breakpoint.Tablet)} {
      width: 325px;
    }
  }

  ${Button}[type="submit"] {
    width: 100%;
    height: 50px;
    margin-top: 20px;

    ${media(Breakpoint.Tablet)} {
      border-left: 0;
      margin: 0;
    }
  }

  ${media(Breakpoint.Tablet)} {
    display: flex;
  }
`;

interface SubscribeFormProps {
  className?: string;
  onSubmit?: React.FormEventHandler;
  onSuccess?: () => void;
}

interface MailchimpResponse {
  result: string;
  msg: string;
}

function SubscribeForm({ className, onSubmit, onSuccess }: SubscribeFormProps) {
  const [formError, setFormError] = useState<string>("");
  const [formState, setFormState] = useState<"default" | "submitting" | "submitted">("default");
  const [email, setEmail] = useState<string>("");

  const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    setFormState("submitting");
    e.preventDefault();
    if (isValidEmail(email)) {
      setFormError("");
      // submit action goes here
      addToMailchimp(email).then((data: MailchimpResponse) => {
        if (data.result === "success") {
          onSuccess?.();
        } else if (data.msg.includes("is already subscribed to list")) {
          setFormError("Email is already subscribed to list.");
          setFormState("default");
        } else {
          console.log("unexpected error", data.result, data.msg);
        }
      });
    } else {
      setFormError("Invalid Email");
      setFormState("default");
    }
    return true;
  };

  let formContent: JSX.Element;
  if (formState === "submitting" || formState === "submitted") {
    formContent = (
      <Button
        css={{
          width: "100%",
          height: "100%",
          backgroundColor: "transparent !important",
          cursor: "default !important",
        }}
        disabled
        variant="transparent"
      >
        {formState === "submitting" ? "Please Wait..." : "Thank You!"}
      </Button>
    );
  } else {
    formContent = (
      <React.Fragment>
        <InputField
          ariaLabel="Email"
          error={formError}
          placeholder="EMAIL ADDRESS"
          onKeyPressEvent={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
              onSubmit?.(e);
            }
          }}
          id="Email"
          type="email"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.currentTarget.value);
          }}
        />
        <Button type="submit" variant="transparent">
          Submit
        </Button>
      </React.Fragment>
    );
  }

  return (
    <div className={className}>
      <SubscribeFormInner hasError={!!formError} onSubmit={onSubmitForm} css={{ margin: "0 auto" }}>
        {formContent}
      </SubscribeFormInner>
    </div>
  );
}

export default styled(SubscribeForm)`
  position: relative;
  width: 100%;
`;
