/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { Link } from "gatsby";

import { MenuItem } from "./MenuItems";

import { Breakpoint, media, themeColor } from "../../theme";

const OverlayMenuClose = styled.img`
  display: block;
  width: 30px;
  height: 25px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const Logo = styled.img`
  display: block;
  width: 120px;
  height: 60px;
  margin: 20px auto 0;
`;

const MenuListItem = styled.li`
  margin: 0;
  text-align: center;

  a {
    font-family: ${({ theme }) => theme.font.josefinSans};
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-decoration: none;
    color: ${themeColor("white")};
  }
`;

const MenuList = styled.ul`
  display: grid;
  grid-gap: 20px;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
`;

interface MenuOverlayProps {
  className?: string;
  items: MenuItem[];
  overlayMenuToggle: (action: "open" | "close") => void;
}

const MenuOverlay: React.FC<MenuOverlayProps> = ({ className, items, overlayMenuToggle }) => {
  useEffect(() => {
    if (typeof window === "undefined") return;
    const gatsbyDiv = document.getElementById("gatsby-focus-wrapper");
    if (gatsbyDiv) {
      gatsbyDiv.style.height = "100%";
    }
    document.documentElement.style.overflow = "hidden";

    return () => {
      if (gatsbyDiv) {
        gatsbyDiv.style.height = "auto";
      }
      document.documentElement.style.overflow = "auto";
    };
  }, []);

  return (
    <div className={className}>
      <OverlayMenuClose alt="menu" src={`/menu-close.svg`} onClick={() => overlayMenuToggle("close")} />
      <a href="/">
        <Logo alt="logo" src={`/logo.svg`} />
      </a>
      <MenuList css={{ marginTop: "60px" }}>
        {items.map((item, idx) => {
          if (!item.node.path) {
            return undefined;
          }

          return (
            <MenuListItem key={idx}>
              <Link to={item.node.path.toString()}>{item.node.title}</Link>
            </MenuListItem>
          );
        })}
      </MenuList>
    </div>
  );
};

export default styled(MenuOverlay)`
  position: fixed;
  width: 100%;
  height: 100%;
  background: ${themeColor("grey700")};
  color: ${themeColor("white")};
  top: 0;
  left: 0;
  z-index: 100;
  padding: 20px;
  transition: opacity 0.2s linear;

  ${media(Breakpoint.Tablet)} {
    display: none;
  }

  &.fade-enter-done,
  &.fade-exit {
    opacity: 1;
  }

  &.fade-enter,
  &.fade-exit-active {
    opacity: 0;
  }
`;
