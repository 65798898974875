import styled from "@emotion/styled";
import React from "react";

interface MainProps {
  className?: string;
  children: React.ReactNode;
}

const Main: React.FC<MainProps> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default styled(Main)`
  flex: 1 0 auto;
`;
