import { useRef, useState, useMemo, useEffect } from 'react'

interface UseIsFocusedOptions {
    initialFocus?: boolean
    onBlur?: React.FocusEventHandler
    onFocus?: React.FocusEventHandler
}

/** helpers to track the focus states of an input or a textarea */
export default function useIsFocused<T extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement>({
    initialFocus,
    onBlur,
    onFocus,
}: UseIsFocusedOptions) {
    const ref = useRef<T>(null)
    const [isFocused, setIsFocused] = useState(false)
    const handlers = useMemo(
        () => ({
            onBlur(e: React.FocusEvent) {
                onBlur?.(e)
                setIsFocused(false)
            },
            onFocus(e: React.FocusEvent) {
                onFocus?.(e)
                setIsFocused(true)
            },
        }),
        [onBlur, onFocus]
    )

    useEffect(() => {
        if (initialFocus) {
            ref.current?.focus()
        }
    }, [initialFocus])

    return { isFocused, ref, ...handlers }
}
