import styled from "@emotion/styled";

export default styled.a`
  && {
    display: inline-block;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
`;
